import React from "react";
import { Link, graphql } from "gatsby";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import slugGenerator from "../util/slugGenerator";

import "./LegalPage.css";

export const query = graphql`
  query {
    allAiTool {
      edges {
        node {
          title
          slug
          hasGuide
        }
      }
    }
    allCategory {
      edges {
        node {
          title
        }
      }
    }
  }
`;

const SitemapPage = ({ data }) => {
  const tools = data.allAiTool.edges;
  const categories = data.allCategory.edges;

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>SITEMAP</SectionHeading>
          <div>Tools</div>
          <div style={{ maxWidth: 900 }}>
            {tools.map((tool) => (
              <>
                <Link to={`/${tool.node.slug}-review/`}>
                  <div>{tool.node.title}</div>
                </Link>
                {tool.hasGuide && (
                  <Link to={`/how-to-use-${tool.node.slug}/`}>
                    <div>{tool.node.title} Guide</div>
                  </Link>
                )}
                {/*
              {tool.review && <Link to={`/${tool.node.slug}-review/our-review/`}>
                <div>{tool.node.title} Review</div>
              </Link>} */}
              </>
            ))}
          </div>
          <div>Categories</div>
          <div style={{ maxWidth: 900 }}>
            {categories.map((category) => (
              <Link to={`/${slugGenerator(category.node.title)}/`}>
                <div>{category.node.title}</div>
              </Link>
            ))}
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default SitemapPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"AI Product Reviews"}
      pathname={location.pathname}
    />
  );
};
